<template>
  <div class="about">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title" id="title">Hire Node.js Developers</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a id="title2">Use Node.js to Be a Long-Term Business Player</a>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== CASE DETAILS PART START ======-->

    <div class="case-details-area pt-120 pb-115">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="case-details-content mt-50 pb-20">
              <h2 class="title" id="hearder1">Work with the best Node.js Company</h2>
              <br />
              <p id="description1">
                At Kibbutz techs, we have always believed that the limitations of a
                Node.js consultant should not hinder your imagination and
                requirements. We don’t compromise on skills and experience while
                recruiting developers on board. Our team of super-skilled
                developers is capable of serving you with all kinds of your
                needs..
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="case-details-content mt-50 pb-20">
              <h2 class="title" id="hearder2">Why to hire us for Node.js Development Services?</h2>
              <br />
              <p id="description2">
                24-hour support by a Kibbutz techs representative backed by a team of
                diligently working developers who are accountable for everything
                related to the project on the floor will be pleased to support
                you by delivering work matching the quality which remains
                unmatched in the market..
              </p>
            </div>
          </div>

          <div class="col-lg-6 order-1 order-lg-2">
            <div class="faq-accordion-3 mt-30">
              <div class="accordion" id="accordionExample-2">
                <i aria-hidden="true" class="fa fa-check">You pay for what you get.</i>
              </div>
            </div>
            <!-- faq accordion -->
            <div class="faq-accordion-3">
              <div class="accordion" id="accordionExample-2">
                <i aria-hidden="true" class="fa fa-check">We believe in tech craftsmanship</i>
              </div>
            </div>
            <!-- faq accordion -->
            <div class="faq-accordion-3">
              <div class="accordion" id="accordionExample-2">
                <i aria-hidden="true" class="fa fa-check">
                  We are not lazy to serve even after the project is winded
                  up.
                </i>
              </div>
            </div>
            <!-- faq accordion -->
            <div class="faq-accordion-3">
              <div class="accordion" id="accordionExample-2">
                <i aria-hidden="true" class="fa fa-check">We are 50 Projects old.</i>
              </div>
            </div>
            <!-- faq accordion -->
            <div class="faq-accordion-3">
              <div class="accordion" id="accordionExample-2">
                <i
                  aria-hidden="true"
                  class="fa fa-check"
                >We have earned a client base of 80+ Satisfied customers.</i>
              </div>
            </div>
            <!-- faq accordion -->
          </div>
        </div>
      </div>
    </div>

    <!--====== CASE DETAILS PART ENDS ======-->
  </div>
</template>

<script>

import $ from "jquery";

export default {
  components: {  },
  props: {
  },
  data() {
    return {
      hire:null,
      url_data:null,
      
    };
  },
  mounted(){
      // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0,0);
      this.fillupdata();
  },
    watch: { 
     '$route.params': {
        handler:async function() {
          //  console.log("search",params)
            await this.fillupdata();
          //  console.log("search22",params)
        },
        deep: true,
        immediate: true
      }
},
  methods: {
  fillupdata(){
          this.url_data = this.$route.query.name;
      // console.log("this.url_data",this.url_data)
  var Hire = {
      Hire_React_Native_Developers: {
        title: "React Native Developers ",
        title2: "Raise the bar of mobile apps with React Native",
        hearder1: "",
        // hearder1: "Hire the best React Native Developer Company: Kibbutz techs",
        hearder2: "What makes Kibbutz technicians so good at React Native development?",
        description1:
          "Kibbutz techs do not like productivity unless it is at its optimum. Kibbutz Techs is a React Native app development company that employs the most intelligent and sincere React Native app developers. To create Generic Android & iOS apps, our React Native tech developersleverage the newest React JavaScript framework and tools. You can hire our dynamic designers and dedicated developers who are experienced with React Native. We've concentrated on delivering client-centric React Native mobile app developmentsince Kibbutz technologists first raised their heads in React Native app development.",
        description2:
          "Kibbutz Techs is one of India's oldest React Native development businesses, which should give you confidence in our abilities. When you hire React Native app developers from us, you can rest assured that your timing expectations will be reached precisely, and we will go to great lengths to meet them. We can provide you with entire overall development packages because we have enough engineers, designers, and project managers to help you"
      },
      Hire_Android_Developers: {
        title: "Android Developers",
        title2:"Let Your Business Reach New Heights with Android's Popularity",
        hearder1: "",
        // hearder1: "Hire the best Android app development company: Kibbutz techs",
        hearder2: "How did Kibbutz technologists conquer Android app development? ",
        description1:
          "Since the inception of Android, kibbutz technologies have dominated the in-app market share and have grown in popularity. Over years of experience, our android app developers have mastered the art of blending domain expertise and agile methodology. Kibbutz techs are pleased to inform you that we have a varied background in multi-disciplinary app development projects. If you want customised yet dependable Android apps at a low cost, we are the company to hire.",
        description2:
          "A Kibbutz technologies Android app developer is regarded for having unbeatable uniqueness and innovation, ensuring that people are riveted to their displays. Our developers work expertly with heavy software such as the Android Studio, libraries, Java, and everything else needed to create a captivating Android app. Whether you hire us or not, we offer free consultations for developing the right apps for the Google Play Store in order to fully understand the client's needs. Regardless of the circumstances, we stay in touch with you throughout your project. Our Android developers combine their expertise with your vision to create conceptual Android apps with intriguing features that distinguish your app."
      },
      Hire_iOS_Developers: {
        title: "iOS Developers ",
        title2:
          "Create apps for some of the most dependable mobile devices",
        hearder1: "",
        // hearder1: "Work with the best iOS app development Company: Kibbutz techs",
        hearder2: "Why are Kibbutz tech's iOS applications so unique? ",
        description1:
          "Kibbutz techs are without a doubt the best iPhone app development company to work with. Our iOS developers have learned the steps required to build an iOS application from their previous experiences. We ensure that your iOS apps are not only compliant but also pleasantly user-friendly, thanks to our decade of experience. We create iPhone apps for everyone from small businesses to large multinationals.",
        description2:
          "Kibbutz technicians have the cutting-edge infrastructure, which is a basic requirement but ultimately boosts the productivity of our iOS developers. Kibbutz Techs' developers and experts work tirelessly to create an error-free, high-end, bug-free application. Our developers have a habit of providing you with tailormade solutions in a timely manner, which our clients have appreciated over the years."
      },
      Hire_React_Developers: {
        title: "React.js Developers",
        title2: "Create the history of excellence on the web with React.js",
        hearder1: "",
        // hearder1: "Work with the best React.js Company: Kibbutz techs",
        hearder2: "How does Kibbutz Technologies start a React.js project from the ground up?",
        description1:
          "Kibbutz Tech is one of India's most well-known offshore software development companies, with a global presence. The company's traditional speciality general skills in numerous development platforms have helped it span a platform set that includes React.js and many other such development platforms. In many difficult React.js application projects, the company is known for completing projects successfully and on time. These included creating complex functionalities that were compatible with your cohesive front-end. The company has used React.js in a variety of service and industry verticals, including fintech, healthcare, and general application development projects. The extensive experience gained will showcase their abilities and assure you of the greatest application you can manage for your goods.",
        description2:
          "To help you rely on our experience, Kibbutz techs is one of India's oldest React.js development companies. When we work with React.js, your timeline expectations will be met precisely, and we will strive to meet them at all costs. In comparison to any other vendor in the industry, we offer the most competitive prices with specific standards to meet."
      },
      Hire_Vue_Developers: {
        title: "Vue.js Developers",
        title2: "Enhance your applications with Vue.js ",
        hearder1: "",
        // hearder1: "Work with the best Vue.js Company: Kibbutz techs",
        hearder2: "Why will you choose us?",
        description1:
          " Kibbutz technologists strive to build and offer stunning web interfaces for all customer needs within your business process. With the virtual DOM elements available to be cast and  re-rendered quickly, this platform enables adaptable performance. These advantages put us in the best position to assist you in curating the best web interfaces of recent trends. Kibbutz techs are well-known for their unrivalled expertise in cutting-edge mobile and web development technologies and frameworks. Vue.JS first appeared on the global stage in 2013, and since then, Kibbutz tech developers have been constantly honing their skills to understand and deploy view JS for all client needs and web applications into faces.",
        description2:
          "Quality is the most important factor that keeps us going and motivated to raise the bar every time at Kibbutz Techs. The developers understand the 'best code is no code' philosophy, which allows us to create dependable applications for your business scenario. In comparison to any other vendor in the industry, we offer the most competitive prices with specific standards to meet"
      },
      Hire_Node_Developers: {
        title: "Node.js Developers",
        title2: "Node.js is a long-term business player",
        hearder1: "",
        // hearder1: "Work with the best Node.js Company: Kibbutz techs",
        hearder2: "Why will you hire us?",
        description1:
          " Kibbutz Techs has always believed that the limitations of Node.js developers should not limit your creativity and requirements. Our team of highly skilled developers is capable of meeting all of your requirements. When it comes to recruiting developers, wemake no compromises on skills and experience. ",
        description2:
          "24-hour support from a Kibbutz technicians’ representative, backed by a team of hardworking developers responsible for everything linked to the project on the ground, would be delighted to assist you by producing work of unrivalled quality."
      },
      Hire_Laravel_Developers: {
        title: "Laravel Developers",
        title2: "Create a new dimension to your business with Laravel technology",
        hearder1: "",
        // hearder1: "Work with the best Laravel Company: Kibbutz techs",
        hearder2: "What makes Kibbutz best in Laravel?",
        description1:
          " At Kibbutz Techs, we've always thought that a consultant's limit should not limit your creativity or requirements. Our staff of highly qualified developers is capable of meeting all of your requirements. Using industrystandard web development tools and clean, safe, modular code that has undergone rigorous testing and evaluation, our very talented Laravel developers are in charge of creating and maintaining cutting-edge web applications.",
        description2:
          "24-hour support from a Kibbutz technicians’ representative, backed by a team of hardworking developers responsible for everything linked to the project on the ground, would be delighted to assist you by producing work of exceptional quality."
      },
      Hire_flutter_Developers: {
        title: "Flutter Developers ",
        title2: "Shine like gold in your business with Flutter",
        hearder1: "",
        // hearder1: "Work with the best Flutter Company: Kibbutz techs",
        hearder2: "Kibbutz is best in Flutter",
        description1:
          "When it comes to hiring developers, we don't cut corners on talents and expertise. Our incredibly gifted Flutter developers take charge of apps, overcome technical obstacles, and take delight in their work",
        description2:
          "24-hour support from a Kibbutz technicians’ representative, backed by a team of hardworking developers responsible for everything linked to the project on the ground, would be delighted to assist you by producing work of exceptional quality. Our developers develop innovative and modern web applications using web development tools, build and test reusable and efficient PHP modules, design and maintain databases, perform backend and UI tests on applications to optimize performance, anticipate any changes during development, and follow new industry trends and best practices in web development."
      },
      Hire_Unity_3D_Developers: {
        title: "Hire Unity 3D Developers",
        title2: "Build your grand brand with 2D/3D Game Development. ",
        hearder1: "Work with the best Unity 3D Company: Kibbutz techs",
        hearder2: "How is Kibbutz techs Unmatched at Unity Game Development? ",
        description1:
          "Kibbutz techs is the right place for all your Unity 2D/3D game development needs. Our Kibbutz techs developer is a fleet of tech-savvies who know the ultimate gaming platform n and out. Also, they are proficient at cross mobile app development for all platforms, graphics resolutions, and hardware specifications. Every Kibbutz techs developer is willing to toil hard for an exclusive gaming environment to capture the interests. We promise you noteworthy applications which are highly influential to conquer your customer’s interests. Hire our Kibbutz techs Unity 3D game developers for acquiring the best game development services and features at a practical cost.",
        description2:
          "Our developers develop innovative and modern web applications using web development tools, build and test reusable and efficient PHP modules, design and maintain databases, perform backend and UI tests on applications to optimize performance, anticipate any changes during development, and follow new industry trends and best practices in web development."
      }
};
    // console.log("Hire",Hire)
    var item = Hire[this.url_data];
      // console.log("item...", item);
      // console.log("item...", item.title);
    $(document).ready(function() {
        $("#title").text(item.title);
        $("#title2").text(item.title2);
        $("#hearder1").text(item.hearder1);
        $("#hearder2").text(item.hearder2);
        $("#description1").text(item.description1);
        $("#description2").text(item.description2);
  });

  }
  }
};
</script>
